import img from "../assets/img/promo/phone-promo-1.png";
import { AppStoreSvg, GooglePlaySvg } from "../components/UI/Svg";

export const dataPromo = {
  title: "Приложение для мам и пап о колыбельных и не только",
  subtitle:
    "все, что вы хотите знать о родительском пении и музыке во время беременности и в первые годы развития ребенка",
  link1: <AppStoreSvg />,
  link2: <GooglePlaySvg />,
  image: img,
};
