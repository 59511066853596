import { Container } from "react-bootstrap";
import Slider from "../../slider/Slider";

const Team = (props) => {
  const { data } = props;
  return (
    <section className="team">
      <Container>
        <h2 className="team__title title-dark">{data.title}</h2>
        <Slider data={data.items2} />
      </Container>
    </section>
  );
};

export default Team;
