import { Col, Container, Row } from "react-bootstrap";

const Status = () => {
  return (
    <section className="status">
      <Container>
        <Row>
          <Col className="col-md-8 offset-md-2">
            <div className="status__title title-dark">
              Раскрой свою супер-силу - свой голос
            </div>
            <div className="status__subtitle">
              Узнай все про колыбельные, и как с помощью пения и музыки
              поддерживать здоровье и развитие малыша
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Status;
