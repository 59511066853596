import { useState, useEffect } from 'react';
import NavbarHeader from './NavbarHeader';
import { Container, Row, Col } from 'react-bootstrap';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <header className="header">
      <Container className={`header__container ${scrolled ? 'scrolled' : ''}`}>
        <Row>
          <Col>
            <NavbarHeader scrolled={scrolled} />
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
