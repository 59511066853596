import img1 from "../assets/img/advices/phone-1.png";
import img2 from "../assets/img/advices/phone-2.png";
import img3 from "../assets/img/advices/phone-3.png";

export const dataAdvices = [
  {
    image: img1,
    title: "Получай советы на каждый день",
    description:
      "С помощью приложения GuliGuli ты узнаешь, как из любой любимой песни сделать колыбельную и сможешь записать свою",
  },
  {
    image: img2,
    title: "Учитывает твои интересы",
    description:
      "GuliGuli учитывает твои музыкальные вкусы и что нужно тебе именно сейчас в данный момент родительства",
  },
  {
    image: img3,
    title: "Отслеживай свой статус",
    description:
      "У тебя всегда под рукой вся необходимая информация от проверенных экспертов GuliGuli",
  },
];
