import { Fragment, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

const Tabs = (props) => {
  const { data } = props;
  const [tab, setTab] = useState(data[0]);

  const TabsItem = (props) => {
    const { item } = props;

    return (
      <li
        onClick={() => props.onChange(item)}
        className={tab.id === item.id ? "tabs__item active" : "tabs__item"}
      >
        <div className="tabs__item-icon">{item.icon}</div>
        <div className="tabs__item-descr">{item.title}</div>
        <div
          className={
            tab.id === item.id ? "tabs__item-check active" : "tabs__item-check"
          }
        >
          {item.check}
        </div>
      </li>
    );
  };
  return (
    <div className="tabs">
      <Container>
        <Row>
          <Col className="col-md-3 align-self-center pe-0">
            <ul className="tabs__list">
              {data.map((item) => {
                return <TabsItem item={item} onChange={setTab} key={item.id} />;
              })}
            </ul>
          </Col>
          <Col className="col-md-3 offset-1 pe-0 order-sm-2 order-3 col-6">
            <div className="tabs__imgs">
              {data.map((item) => {
                return (
                  <img
                    key={item.id}
                    src={item.image}
                    alt=""
                    className={
                      tab.id === item.id
                        ? "tabs__imgs-img active"
                        : "tabs__imgs-img"
                    }
                  />
                );
              })}
            </div>
          </Col>
          <Col className="col-md-4 offset-1 align-self-center pe-0 order-sm-3 col-8 order-2 mx-auto">
            <div className="tabs__descr">
              {data.map((item) => {
                return (
                  <Fragment>
                    <div
                      key={`${item.id}`}
                      className={
                        tab.id === item.id
                          ? "tabs__descr-txt active"
                          : "tabs__descr-txt"
                      }
                    >
                      <p>{item.description}</p>
                      {item.description2 && <p>{item.description2}</p>}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
      <div></div>
    </div>
  );
};

export default Tabs;
