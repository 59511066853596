import { FaHeadphones } from 'react-icons/fa';

const Logo = ({ light }) => {
  return (
    <div className={`logo ${light ? 'logo-light' : ''}`}>
      <FaHeadphones className="logo__icon" />
      <span className="logo__txt">Music</span>
    </div>
  );
};

export default Logo;
