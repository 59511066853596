import img1 from "../assets/img/tabs/phone-1.png";
import img2 from "../assets/img/tabs/phone-2-1.png";
import img3 from "../assets/img/tabs/phone-3-1.png";
import { CheckTab, Tab1, Tab2, Tab3 } from "../components/UI/Svg";

export const dataTabs = [
  {
    id: 0,
    icon: <Tab2 />,
    title: "Ждем ребенка",
    check: <CheckTab />,
    image: img1,
    description:
      "Узнай, как с помощью пения и музыки поддерживать свое здоровье и развитие малыша во время беременности с GuliGuli",
    description2:
      "С помощью приложения GuliGuli можно узнать все про пение во время беременности, какую музыку выбрать для поддержки настроения и почему это важно для здоровья и развития малыша слышать пение мамы и папы",
  },
  {
    id: 1,
    icon: <Tab3 />,
    title: "Малышу от 0 до 3 лет",
    check: <CheckTab />,
    image: img2,
    description:
      "С помощью приложения GuliGuli ты узнаешь, как связано пение мамы и папы со снижением стресса для всей семьи, как колыбельные и потешки могут помочь в период восстановления после родов (с ГВ, с установлением режима сна-бодрствования и профилактикой послеродовой депрессии)",
    description2: "",
  },
  {
    id: 2,
    icon: <Tab1 />,
    title: "На каждый день",
    check: <CheckTab />,
    image: img3,
    description:
      "Узнай, как с помощью пения и музыки поддерживать свое здоровье и развитие малыша во время беременности и в первые годы его жизни с помощью приложения GuliGuli",
    description2: "",
  },
];
