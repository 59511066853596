import React, { useRef, useState } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Slider = (props) => {
  const { data } = props;

  const items = data.map((item, i) => {
    return (
      <SwiperSlide key={i}>
        <div className="slider__item">
          <img src={item.image} alt="" className="slider__item-img" />
          <div className="slider__item-name">{item.name}</div>
          <div className="slider__item-position">{item.position}</div>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className="slider">
      <Swiper
        className="swiper"
        slidesPerView={3}
        // spaceBetween={30}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        modules={[Navigation]}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            // spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            // spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}>
        {items}
      </Swiper>
      <button className="swiper-button-prev">
        <FaArrowLeft className="swiper-button-icon" />
      </button>
      <button className="swiper-button-next">
        <FaArrowRight className="swiper-button-icon" />
      </button>
    </div>
  );
};

export default Slider;
