import { Col, Container, Row } from "react-bootstrap";

const Footer = (props) => {
  const { data } = props;

  return (
    <footer className="footer">
      <Container>
        <div className="footer__wrapper">
          <Row className="d-flex column-gap-5 ">
            <Col className="col-md-3 p-0">
              <div className="footer__left">
                <div className="footer__logo">{data.logo}</div>
                <div className="footer__title">{data.title}</div>
                <div className="footer__social">{data.social}</div>
                <div className="footer__name">{data.name}</div>
                <div className="footer__rights">{data.rights}</div>
              </div>
            </Col>
            <Col className="col-md-4 p-0 col-12">
              <div className="footer__contacts">
                <div className="footer__contacts-block">
                  <div className="footer__contacts-title">
                    {data.contactsTitle}
                  </div>
                  <div>
                    <div className="footer__contacts-phone">
                      {data.contactsPhone}
                    </div>
                    <div className="footer__contacts-social">
                      {data.contactsSocial}
                    </div>
                  </div>
                  <a
                    href="https://t.me/ddampty"
                    className="footer__contacts-link"
                  >
                    {data.contactsLink}
                  </a>
                  <a
                    href="mailto:lovkova@mail.ru"
                    className="footer__contacts-link"
                  >
                    {data.contactsEmail}
                  </a>
                </div>

                <div className="footer__contacts-address">
                  <div>
                    <div className="footer__contacts-address--title">
                      {data.contactsAddress1}
                    </div>
                    <div className="footer__contacts-address--descr">
                      {data.contactsDescription1}
                    </div>
                  </div>
                  <div>
                    <div className="footer__contacts-address--title">
                      {data.contactsAddress2}
                    </div>
                    <div className="footer__contacts-address--descr">
                      {data.contactsDescription2}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="footer__phone">
                <div className="footer__phone-title title-dark">
                  {data.download}
                </div>
                <div className="footer__phone-links">
                  <a href="#" className="footer__phone-link">
                    {data.link1}
                  </a>
                  <a href="#" className="footer__phone-link">
                    {data.link2}
                  </a>
                </div>
                <div className="footer__phone-docs">
                  <a href="/policy" className="footer__phone-doc">
                    {data.policy}
                  </a>
                  <a href="/policy" className="footer__phone-doc">
                    {data.doc}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
