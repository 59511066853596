import imgSm1 from "../assets/img/partners/partners-small-1.svg";
import imgSm2 from "../assets/img/partners/partners-small-2.svg";
import imgSm3 from "../assets/img/partners/partners-small-3.svg";
import imgLeft from "../assets/img/partners/partners.svg";

export const dataPartners = {
  title:
    "«мама, папа - пой» - уникальная программа подготовки к родам и воспитанию малыша",
  imageLeft: imgLeft,
  images: [imgSm1, imgSm2, imgSm3],
};
