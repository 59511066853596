import Advices from "../components/layout/advices/Advices";
import Content from "../components/layout/content/Content";
import Download from "../components/layout/download/Download";
import Founder from "../components/layout/founder/Founder";
import Numbers from "../components/layout/numbers/Numbers";
import Partners from "../components/layout/partners/Partners";
import Promo from "../components/layout/promo/Promo";
import Status from "../components/layout/status/Status";
import Team from "../components/layout/team/Team";
import Tabs from "../components/tabs/Tabs";

import { dataPromo } from "../data/dataPromo";
// import { dataFooter } from '../data/dataFooter';
import { dataAdvices } from "../data/dataAdvices";
import { dataContent } from "../data/dataContent";
import { dataDownload } from "../data/dataDownload";
import { dataFounder } from "../data/dataFounder";
import { dataPartners } from "../data/dataPartners";
import { dataTabs } from "../data/dataTabs";
import { dataTeam } from "../data/dataTeam";

const HomePage = () => {
  return (
    <>
      {/* <Header />*/}
      <Promo data={dataPromo} />
      <Partners data={dataPartners} />
      <Numbers />
      <Content data={dataContent} />
      <Status />
      <Tabs data={dataTabs} />
      <Advices data={dataAdvices} />
      <Download data={dataDownload} />
      <Founder data={dataFounder} />
      <Team data={dataTeam} />
      {/* <Footer data={dataFooter} />*/}
    </>
  );
};

export default HomePage;
