import { Col, Container, Row } from "react-bootstrap";

const Content = (props) => {
  const { data } = props;

  const ContentItem = (props) => {
    const { data } = props;

    return (
      <div className="content__item">
        <div className="content__item-wrapper">
          <h2 className="content__item-title">{data.title}</h2>
          <p className="content__item-descr">{data.description}</p>
          {/* <a href="#" className="content__item-link">
            {data.link}
          </a>*/}
        </div>
        <img src={data.image} alt="" className="content__item-img" />
      </div>
    );
  };

  return (
    <section className="content">
      <Container>
        <Row>
          <Col className="col-md-6">
            <div className="content__wrapper">
              <h2 className="content__title title-dark">{data.title1}</h2>
              <span className=" title-light text-important">{data.title2}</span>
              <h2 className="content__title title-dark">{data.title3}</h2>
              <p className="content__txt">{data.description}</p>
            </div>
          </Col>
          {data.items.map((item, i) => {
            return (
              <Col className="col-md-6 pt-3 content__col" key={i}>
                <ContentItem data={item} />{" "}
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Content;
