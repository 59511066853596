import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import CountDownPage from "../components/pages/CountDown/CountDown.page";
import { SendGA4, SendYA } from "../components/utils/Analytics";
import { HomePage, NotFoundPage, PolicyPage } from "../pages";
import AppLayout from "./AppLayout";

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics - Send pageview with a custom path
    //ReactGA.set({ page: location.pathname });
    //ReactGA.pageview(location.pathname + location.search);
    SendGA4(location.pathname + location.search);

    // Yandex Analytics - Send pageview with a custom path
    SendYA(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      {/*<Route path="/countdown" element={<CountDownPage />} />*/}

      <Route path="/" element={<AppLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/countdown" element={<CountDownPage />} />

        <Route path="/policy">
          <Route index element={<PolicyPage />} />
          {/*  <Route path="terms" element={<TermsPage />} />*/}
        </Route>

        {/*  <Route path="/blog" element={<BlogLayout />}>
          <Route index element={<PostsListPage />} />
          <Route path=":slug" element={<SinglePostPage />} />
          <Route path="category/:slug" element={<PostsByCategoryPage />} />
          <Route path="tag/:slug" element={<PostsByTagPage />} />
        </Route>*/}

        {/* Catch all route */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
