import img1 from "../assets/img/content/content-img1.svg";
import img2 from "../assets/img/content/content-img2.svg";
import img3 from "../assets/img/content/content-img3.svg";

export const dataContent = {
  title1: "Контент создают",
  title2: "музыкальные терапевты и эксперты",
  title3: "медицинской сферы",
  // description: 'Библиотека видеоуроков и текстовых статей для подготовки к родительству',
  description:
    "Все о супер-силе, которая есть у каждого родителя – о голосе и о том, как и когда им пользоваться",
  items: [
    {
      title: "Советы",
      description: "Короткие видео с быстрыми ответами на вопросы",
      link: "выбрать категорию",
      image: img1,
    },
    {
      title: "Курсы",
      description:
        "Серия видео, в которых подробно разбираются важные темы и вопросы",
      link: "выбрать категорию",
      image: img2,
    },
    {
      title: "Посты",
      description: "Полезные авторские статьи экспертов на актуальные темы",
      link: "выбрать категорию",
      image: img3,
    },
  ],
};
