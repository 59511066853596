import img1 from "../assets/images/team/team-1.png";
import img2 from "../assets/images/team/team-2.png";
import img3 from "../assets/images/team/team-3.png";
import img4 from "../assets/images/team/team-4.png";
import img5 from "../assets/images/team/team-5.png";
import img6 from "../assets/images/team/team-6.png";
import img7 from "../assets/images/team/team-7-1.png";

export const dataTeam = {
  title: "Наша команда",
  items: [
    {
      image: img1,
      name: "Дарья Водчиц",
      position: "Event-manager / Организатор",
    },
    {
      image: img2,
      name: "Дмитрий Мардас",
      position: "CTO / Технический директор",
    },
    {
      image: img3,
      name: "Екатерина Шубёнкина",
      position: "Екатерина Шубёнкина",
    },
    {
      image: img4,
      name: "Марина Лукашкина",
      position: "Developer / Разработчик",
    },
    {
      image: img5,
      name: "Алеся Добыш",
      position: "Editor / Редактор, журналист",
    },
    {
      image: img6,
      name: "Наталья Гончарик",
      position: "Moderator / Модератор",
    },
  ],

  items2: [
    {
      index: "1",
      name: "Maria Komarova",
      position: "Founder, Chief Executive Officer",
      description:
        "Aliquam iure quaerat voluptatem praesentium possimus under laudantium vel dolorum distinctio dire flow",
      image: img7,
      socials: [
        { type: "tg", link: "https://t.me/ddampty/" },
        { type: "ln", link: "https://www.linkedin.com/in/lovkovav/" },
        { type: "fb", link: "https://www.facebook.com/lovkovav/" },
        { type: "instagram", link: "https://www.instagram.com/ddampty/" },
      ],
      aosDelay: "100",
    },
    {
      index: "2",
      name: "Alexander Lovkov",
      position: "Co-Founder, СТО",
      description:
        "Aliquam iure quaerat voluptatem praesentium possimus under laudantium vel dolorum distinctio dire flow",
      image: img2,
      socials: [
        { type: "tg", link: "https://t.me/ddampty/" },
        { type: "ln", link: "https://www.linkedin.com/in/lovkovav/" },
        { type: "fb", link: "https://www.facebook.com/lovkovav/" },
        { type: "instagram", link: "https://www.instagram.com/ddampty/" },
      ],
      aosDelay: "200",
    },
    {
      index: "3",
      name: "Sergey Shishkov (Stalker)",
      position: "Psychologist consultant",
      description:
        "Labore ipsam sit consequatur exercitationem rerum laboriosam laudantium aut quod dolores exercitationem ut",
      image: img1,
      socials: [
        { type: "fb", link: "/" },
        { type: "ln", link: "/" },
        {
          type: "instagram",
          link: "https://www.instagram.com/stalker_sergey_shishkov/",
        },
      ],
      aosDelay: "300",
    },
    {
      index: "4",
      name: "Marya Evsenkova",
      position: "CMO",
      description:
        "Illum minima ea autem doloremque ipsum quidem quas aspernatur modi ut praesentium vel tque sed facilis at qui",
      image: img3,
      socials: [
        { type: "tg", link: "https://t.me/evsenkovapromarketing" },
        { type: "fb", link: "https://www.facebook.com/MariaEvsenkova" },
        {
          type: "ln",
          link: "https://www.linkedin.com/in/marya-evsenkova-b8b55072/",
        },
        {
          type: "instagram",
          link: "https://www.instagram.com/mara.morevna/",
        },
      ],
      aosDelay: "400",
    },
    // {
    //   index: "4",
    //   name: "Vasilii Dudorov",
    //   position: "CIO/СТО, Co-founder",
    //   description:
    //     "Magni voluptatem accusamus assumenda cum nisi aut qui dolorem voluptate sed et veniam quasi quam consectetur",
    //   image: img4,
    //   socials: [
    //     { type: "tg", link: "https://t.me/DudorovVasilii" },
    //     { type: "ln", link: "https://www.linkedin.com/in/vasilii-dudorov/" },
    //     { type: "fb", link: "https://www.facebook.com/vasiliy.dudorov/" },
    //   ],
    //   aosDelay: "400",
    // },
    // {
    //   index: "5",
    //   name: "Marina Paliv",
    //   position: "CSO, Sales",
    //   description:
    //     "Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit",
    //   image: img5,
    //   socials: [
    //     { type: "tg", link: "https://t.me/MarinaPali" },
    //     {
    //       type: "ln",
    //       link: "https://www.linkedin.com/in/marina-pali-44a0b721/",
    //     },
    //   ],
    //   aosDelay: "500",
    // },
    {
      index: "6",
      name: "Evgeniy Abramov",
      position: "Front-end developer",
      description:
        "Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel",
      image: img6,
      socials: [
        { type: "fb", link: "/" },
        { type: "instagram", link: "/" },
      ],
      aosDelay: "500",
    },
  ],
};
