// import { dataFooter } from '../data/dataFooter';

import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

const PolicyText = () => {
  return (
    <Fragment>
      <p class="c20">
        <span class="c3 c7">
          Пользовательское соглашение об использовании мобильного Приложения
        </span>
      </p>
      <p class="c20">
        <span class="c3 c7">ПАО Сбербанк</span>
      </p>
      <p class="c4">
        <span class="c3 c0">&nbsp;</span>
      </p>
      <p class="c4">
        <span class="c0">Настоящее пользовательское соглашение (далее – </span>
        <span class="c7">Соглашение</span>
        <span class="c3 c0">
          ) устанавливает условия использования Приложения Банка для Устройств и
          подлежит заключению между Банком и Пользователем.
        </span>
      </p>
      <h1 class="c14" id="h.62nuh4rldi5h">
        <span class="c21">1.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c11">Термины и определения</span>
      </h1>
      <p class="c4">
        <span class="c0">1.1.</span>
        <span class="c1">&nbsp; </span>
        <span class="c7">Банк</span>
        <span class="c3 c0">
          &nbsp;– лицензиар, Публичное акционерное общество «Сбербанк России»
          [ПАО Сбербанк ИНН 7707083893, КПП 775001001, р/с 30301810000006000001,
          к/с 30101810400000000225, БИК 044525225, ОКВЭД 65.12, ОКПО, 00032537,
          ОГРН 1027700132195].
        </span>
      </p>
      <p class="c4">
        <span class="c0">1.2.</span>
        <span class="c1">&nbsp; </span>
        <span class="c7">Официальный сайт Банка</span>
        <span class="c0">&nbsp;- сайт Банка в сети Интернет </span>
        <span class="c0 c27">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/&amp;sa=D&amp;source=editors&amp;ust=1731937650474800&amp;usg=AOvVaw0JrER-fEzTVJkKjaogy9nf"
          >
            www.sberbank.ru
          </a>
        </span>
        <span class="c3 c0">.</span>
      </p>
      <p class="c4">
        <span class="c0">1.3.</span>
        <span class="c1">&nbsp; </span>
        <span class="c7">Пользователь </span>
        <span class="c3 c0">
          – лицензиат, физическое лицо (Клиент Банка), оформившее заявление на
          банковское обслуживание или заявление на получение банковской карты
          Банка в соответствии с условиями банковского обслуживания физических
          лиц и/или использования банковских карт ПАО Сбербанк, установившее
          Приложение Банка на Устройство.
        </span>
      </p>
      <p class="c4">
        <span class="c0">1.4.</span>
        <span class="c1">&nbsp; </span>
        <span class="c7">Приложение Банка</span>
        <span class="c3 c0">
          &nbsp;– программа для ЭВМ под наименованием «Мобильное приложение
          Сбербанк Онлайн» (мобильная версия системы дистанционного обслуживания
          Сбербанк Онлайн) исключительное право, на которую принадлежит Банку в
          силу факта создания.
        </span>
      </p>
      <p class="c4">
        <span class="c0">1.5.</span>
        <span class="c1">&nbsp; </span>
        <span class="c7">Устройство</span>
        <span class="c3 c0">
          &nbsp;– мобильное техническое устройство (смартфон, планшет или другое
          устройство), имеющее доступ к сети Интернет, на котором установлено
          Приложение Банка.
        </span>
      </p>
      <p class="c4">
        <span class="c0">1.6.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c7">Лицензия </span>
        <span class="c3 c0">
          – простая (неисключительная) непередаваемая отзывная безвозмездная
          лицензия на использование Приложения Банка.
        </span>
      </p>
      <p class="c4">
        <span class="c3 c0">
          По тексту Соглашения также применяются термины, имеющие определение в
          «Условиях банковского обслуживания физических лиц ПАО Сбербанк» и/или
          условиях использования банковских карт ПАО Сбербанк.
        </span>
      </p>
      <h1 class="c14" id="h.o08r3yseh42z">
        <span class="c21">2.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c11">Предмет Соглашения</span>
      </h1>
      <p class="c4">
        <span class="c0">2.1.</span>
        <span class="c1">&nbsp; </span>
        <span class="c3 c0">
          Банк предоставляет Пользователю право на использование Приложения
          Банка на условиях Лицензии в пределах и способами, определенными
          настоящим Соглашением. Информация об использовании и особенностях
          функциональности Приложения Банка, а также системные требования
          содержатся в руководстве по использованию Приложения Банка.
        </span>
      </p>
      <p class="c10 c24">
        <span class="c3 c0">
          2.2.Лицензия предоставляется на следующих условиях:
        </span>
      </p>
      <p class="c9">
        <span class="c0">2.2.1.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Территория действия Лицензии – все страны мира;
        </span>
      </p>
      <p class="c9">
        <span class="c0">2.2.2.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c0">
          Срок действия Лицензии – с даты начала использования Пользователем
          Приложения Банка и принятия Пользователем условий Лицензии
        </span>
        <span class="c7">&nbsp;</span>
        <span class="c0">до момента его удаления</span>
        <span class="c7">&nbsp;</span>
        <span class="c3 c0">
          с Устройства и/или смены Пользователя Устройства;
        </span>
      </p>
      <p class="c9">
        <span class="c0">2.2.3.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Лицензия предоставляет права на использование Приложения Банка
          следующими способами и в следующих пределах:
        </span>
      </p>
      <p class="c13">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          копирование и установка Приложения Банка в память Устройства;
        </span>
      </p>
      <p class="c13">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          воспроизведение Приложения Банка на Устройстве;
        </span>
      </p>
      <p class="c13">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          использование Приложения Банка по прямому функциональному назначению в
          целях, определённых в п. 3.6. Соглашения.
        </span>
      </p>
      <p class="c4 c26">
        <span class="c3 c0">
          Все перечисленные в данном пункте действия по использованию Приложения
          Банка должны осуществляться Пользователем самостоятельно;
        </span>
      </p>
      <p class="c4 c12">
        <span class="c0">2.2.4.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Пользователь может пользоваться неограниченным количеством копий
          Приложения Банка с учетом следующего: на одном Устройстве не
          допускается использование нескольких версий Приложения Банка,
          зарегистрированных на один абонентский номер мобильного телефона;
        </span>
      </p>
      <p class="c4 c12">
        <span class="c0">2.2.5.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          &nbsp;Иные способы использования Приложения Банка, не указанные в
          настоящем Соглашении, запрещены. В частности, Пользователь, не вправе:
        </span>
      </p>
      <p class="c4 c25">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          выдавать сублицензии, продавать, переуступать, давать в пользование и
          аренду, распространять, передавать или иным образом предоставлять
          права на использование Приложения Банка третьим лицам;
        </span>
      </p>
      <p class="c4 c25">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          осуществлять модификацию, усовершенствование, перевод на другие языки,
          декомпилирование, дизассемблирование, декодирование, эмуляцию,
          нарушать целостность, восстанавливать исходный код Приложения Банка
          или каких-либо его частей;
        </span>
      </p>
      <p class="c4 c25">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          осуществлять массовую рассылку информации или файлов с использованием
          Приложения Банка;
        </span>
      </p>
      <p class="c4 c25">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          использовать Приложение Банка в составе коммерческих продуктов, для
          предложения коммерческих продуктов или услуг третьим лицам или в иных
          коммерческих целях;
        </span>
      </p>
      <p class="c4 c25">
        <span class="c0">·</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          использовать Приложение Банка для нарушения прав третьих лиц, а также
          целей, противоречащих действующему законодательству.
        </span>
      </p>
      <h1 class="c14" id="h.k7b43yic7lor">
        <span class="c21">3.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c11">Общие положения</span>
      </h1>
      <p class="c15">
        <span class="c0">3.1.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Использование Приложения Банка на иных условиях, отличных от описанных
          в данном Соглашении не допускается.
        </span>
      </p>
      <p class="c15">
        <span class="c0">3.2.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Перед началом использования Приложения Банка Пользователь обязуется
          ознакомиться с текстом данного Соглашения. Если Пользователь не
          согласен, с каким-либо отдельным положением или с Соглашением в целом,
          он обязан прекратить любое использование Приложения Банка.
          Пользователь, выражая свое согласие с условиями Соглашения в
          соответствии с п. 6.1. Соглашения и в совокупности с фактом
          продолжения использования Приложения Банка, тем самым безоговорочно и
          полностью соглашается со всеми условиями настоящего Соглашения.
        </span>
      </p>
      <p class="c15">
        <span class="c0">3.3.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          В рамках п. 2.2.2., п. 3.2. и п. 6.1. Соглашения под «началом
          использования» следует понимать любые действия направленные на
          использование Приложения Банка или его функционала. Началом
          использования также является, включая, но, не ограничиваясь:
          скачивание, запись и хранение Приложения Банка в памяти Устройства.
        </span>
      </p>
      <p class="c15">
        <span class="c0">3.4.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Руководство по использованию Приложения Банка является неотъемлемой
          частью Соглашения. Нарушение положений руководства по использованию
          Приложения Банка ведет к прекращению действия Соглашения.
        </span>
      </p>
      <p class="c15">
        <span class="c0">3.5.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c0">
          Соглашение, «Политика конфиденциальности мобильного приложения
          Сбербанк Онлайн» для мобильного приложения «Сбербанк Онлайн»
        </span>
        <span class="c17">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftn1&amp;sa=D&amp;source=editors&amp;ust=1731937650477985&amp;usg=AOvVaw1lDu3GXBrRgYVMpcDKEI1i"
          >
            [1]
          </a>
        </span>
        <span class="c3 c0">
          , а также руководства по использованию Приложений Банка публикуются на
          Официальном сайте Банка.
        </span>
      </p>
      <p class="c15">
        <span class="c0">3.6.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Приложение Банка может быть использовано Пользователем для совершения
          операций и получения услуг Сбербанк Онлайн, предусмотренных условиями
          банковского обслуживания физических лиц и условиями использования
          банковских карт ПАО Сбербанк, включая операции переводов денежных
          средств и оплаты товаров и услуг;
        </span>
      </p>
      <p class="c15">
        <span class="c0">3.7.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c0">
          При регистрации Приложения Банка абонентский номер мобильного телефона
          Пользователя сохраняется на серверах Банка
        </span>
        <span class="c17">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftn2&amp;sa=D&amp;source=editors&amp;ust=1731937650478395&amp;usg=AOvVaw04170NPGWxih7D971kX3h5"
          >
            [2]
          </a>
        </span>
        <span class="c3 c0">.</span>
      </p>
      <p class="c15">
        <span class="c0">3.8.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          &nbsp;Принимая условия настоящего Соглашения, в порядке, установленном
          в п. 6.1. Соглашения, Пользователь своей волей и в своем интересе дает
          согласие и предоставляет Банку право:
        </span>
      </p>
      <p class="c9">
        <span class="c0">3.8.1.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          на осуществление автоматизированной обработки (с совершением следующих
          действий: сбор, запись, систематизация, накопление, хранение,
          использование, уничтожение) номера телефона Пользователя, а также
          номеров телефонов из книги контактов Пользователя, находящейся на
          Устройстве, &nbsp;при подтверждении доступа Приложению Банка к книге
          контактов Пользователя посредством операционной системы Устройства, с
          целью предоставления Пользователю удобного сервиса по совершению
          операций переводов денежных средств;
        </span>
      </p>
      <p class="c9">
        <span class="c0">3.8.2.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          на размещение в Приложении Банка рекламных и информационных
          предложений, советов по товарам, работам, услугам дочерних обществ и
          партнеров Банка;
        </span>
      </p>
      <p class="c9">
        <span class="c0">3.8.3.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp;</span>
        <span class="c0">
          на передачу Банком в ООО «Сбербанк–Телеком» (местонахождение: 117947,
          г. Москва, ул. Вавилова, 19) абонентских номеров Пользователя, которые
          обслуживает ООО &nbsp;«Сбербанк–Телеком», для обработки способами и с
          совершением действий, указанных в п. 3.8.1. настоящего Соглашения, в
          целях информирования Пользователя, о состоянии баланса его лицевого
          счета или о задолженности за услуги мобильной связи, об остатках по
          пакетам услуг, а также о детализации расходов на мобильную связь,
          предоставляемую ООО «Сбербанк – Телеком»
        </span>
        <span class="c17">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftn3&amp;sa=D&amp;source=editors&amp;ust=1731937650479137&amp;usg=AOvVaw06Ry-nm8uMlvIQc-KCWTdu"
          >
            [3]
          </a>
        </span>
        <span class="c3 c0">.</span>
      </p>
      <p class="c4">
        <span class="c0">3.9.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c0">
          Согласие Пользователя на обработку персональных данных действует в
          течение всего срока использования Приложения Банка, а также в течение
          5 лет с даты прекращения использования Приложения Банка.
        </span>
      </p>
      <p class="c4">
        <span class="c0">3.10.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Пользователь имеет право и возможность отозвать данное согласие,
          отключив использование Приложения Банка путем изменения
          соответствующих настроек в веб-версии Сбербанк Онлайн.
        </span>
      </p>
      <p class="c4">
        <span class="c0">3.11.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c0 c3">
          Пользователь имеет возможность исключить обработку номеров телефонов
          из книги контактов Пользователя, находящихся на Устройстве путем
          изменения соответствующих настроек в Сбербанк Онлайн.
        </span>
      </p>
      <p class="c4">
        <span class="c0">3.12.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Банк обязуется осуществлять обработку персональных данных
          Пользователя, а также обеспечить конфиденциальность и защиту
          обрабатываемых персональных данных в соответствии с требованиями
          Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных
          данных». При обработке персональных данных Банк принимает необходимые
          правовые, организационные и технические меры для защиты получаемых от
          Пользователя персональных данных от неправомерного или случайного
          доступа к ним, уничтожения, изменения, блокирования, копирования,
          предоставления, распространения персональных данных, а также от иных
          неправомерных действий в отношении получаемых от Пользователя
          персональных данных.
        </span>
      </p>
      <h1 class="c14" id="h.4prwi2zftn9f">
        <span class="c21">4.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c11">Дополнительные условия</span>
      </h1>
      <p class="c4">
        <span class="c0">4.1.</span>
        <span class="c1">&nbsp; </span>
        <span class="c3 c0">
          В случае если использование Приложения Банка рассматривается
          национальным законодательством Пользователя в качестве запрещённой
          деятельности, либо деятельности, на осуществление которой требуется
          получение специальных разрешений, подача уведомлений и т.п.,
          Пользователь обязуется отказаться от использования соответствующих
          функций Приложения Банка, либо Приложения Банка в целом и несёт
          ответственность за невыполнение настоящего условия.
        </span>
      </p>
      <p class="c4">
        <span class="c0">4.2.</span>
        <span class="c1">&nbsp; </span>
        <span class="c3 c0">
          Пользователь настоящим уведомлен и соглашается со следующим:
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.1.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          безопасность и конфиденциальность данных, обрабатываемых на
          Устройстве, обеспечивается непосредственно Пользователем. Пользователь
          понимает и принимает риски, связанные с передачей персональных данных
          и иной конфиденциальной информации с использованием сети Интернет;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.2.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          при смене Пользователя на Устройстве, зарегистрированном при установке
          Приложения Банка, необходимо осуществить регистрацию Приложения Банка
          на &nbsp;Устройстве;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.3.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Банк вправе установить лимиты и ограничения на использование
          Приложения Банка и может менять их по собственному усмотрению, без
          предварительного уведомления Пользователя;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.4.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Банк использует сторонние библиотеки, программное обеспечение для
          сканирования и распознавания QR-кодов для предоставления сервиса
          Приложения Банка. Сторонние библиотеки и программное обеспечение
          используются Пользователем и распространяются Банком на условиях
          соответствующих сторонних лицензий. Список библиотек и наименования их
          лицензий, информация о программном обеспечении размещены в
          руководствах по использованию Приложений Банка;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.5.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Приложение Банка в целях реализации своего полноценного функционала
          может осуществлять доступ к камерам Устройства с целью получения и
          использования фотоизображений в рамках услуг, реализуемых в Приложении
          Банка, в том числе для создания и сохранения фотоизображений в профиле
          пользователя в Приложении Банка, получения фотоизображений платежных
          документов и штрих-кодов, с целью их распознавания и использования для
          совершения операций по переводу денежных средств в Приложении Банка;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.6.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Банк использует сервисы партнеров для предоставления необходимой
          функциональности Приложения Банка на основании договоров, заключенных
          с партнерами Банка;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.7.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Все внешнее программное обеспечение, за исключением указанного в
          п.4.2.4, предоставляется на условиях данного Соглашения;
        </span>
      </p>
      <p class="c4 c6">
        <span class="c0">4.2.8.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        <span class="c3 c0">
          Использование Приложения Банка и передача данных возможны только при
          наличии доступа к сети Интернет. Для бесперебойной работы Приложения
          Банка Пользователю необходимо обеспечить надлежащее качество доступа к
          сети Интернет на своем Устройстве.
        </span>
      </p>
      <h1 class="c14" id="h.xrcyfnmz8lx0">
        <span class="c21">5.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c11">Ответственность Сторон</span>
      </h1>
      <p class="c4 c10">
        <span class="c3 c0">
          5.1.Приложение Банка предоставляется на условиях «как есть» (as is).
          Банк не предоставляет никаких гарантий в отношении соответствия
          Приложения Банка конкретным целям и ожиданиям Пользователя, а также не
          предоставляет никаких иных гарантий, прямо не указанных в Соглашении.
          Ни при каких условиях Банк не несет ответственности перед
          Пользователем за убытки, включая любые прямые, косвенные, умышленные,
          случайные или последующие убытки любого характера проистекающие из
          данного Соглашения или из использования или невозможности
          использования Приложения Банка (включая, но, не ограничиваясь,
          убытками, возникшими в результате потери деловой репутации,
          прекращения работы, технического сбоя, аварии или неисправности или
          любых коммерческих убытков, издержек или потерь, а равно упущенной
          выгоды или неосновательного обогащения) даже если Банку было известно
          или должно было быть известно о возможности такого ущерба или
          Пользователь был предупрежден о возможности такого ущерба.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.2.Банк предпринимает все разумные меры и осуществляет любые
          целесообразные действия, направленные на обеспечение сохранности
          конфиденциальных данных Пользователя и поддержание работоспособности
          Приложения Банка. Банком не производится гарантийное обслуживание
          Приложения Банка.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.3.Банк не несет ответственность за передачу Пользователем информации
          третьим лицам с помощью Приложения Банка, а также, в случае если
          информация из Приложения Банка стала доступна третьим лицам вследствие
          их несанкционированного доступа к Устройству или действий вирусного
          или вредоносного программного обеспечения на Устройстве.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.4.Банк не несет ответственности за невозможность использования
          Приложения Банка и предоставления услуг Сбербанк Онлайн по независящим
          от него обстоятельствам.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.5.Банк оставляет за собой право предоставления функциональности
          Приложения Банка в ограниченном режиме, в том числе по причине не
          предоставления Банку сторонними организациями сервисов, необходимых
          для предоставления услуг в Сбербанк Онлайн.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.6.Соглашение не дает Пользователю никаких прав на использование
          объектов интеллектуальной собственности, включая товарные знаки и
          знаки обслуживания Банка или его партнеров, за исключением прав,
          предоставляемых настоящим Соглашением.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.7.За неисполнение либо ненадлежащее исполнение обязательств, взятых
          на себя Пользователем по Соглашению, а также за нарушения условий
          Лицензии, Пользователь несет ответственность в соответствии с
          законодательством Российской Федерации.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.8.Любое нарушение условий данного Соглашения Пользователем ведет к
          прекращению действия Лицензии.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          5.9.В случае использования Приложения Банка после прекращения действия
          Лицензии, Пользователь несет ответственность в соответствии с
          законодательством Российской Федерации.
        </span>
      </p>
      <h1 class="c14" id="h.mij8new2lh1j">
        <span class="c21">6.</span>
        <span class="c1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <span class="c3 c11">Заключительные положения</span>
      </h1>
      <p class="c4 c10">
        <span class="c0">
          6.1.Принятие Пользователем условий Соглашения осуществляется путем
          активных действий Пользователя (нажатием на кнопку/выбором пункта меню
          Приложения Банка «Подтвердить»
        </span>
        <span class="c17">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftn4&amp;sa=D&amp;source=editors&amp;ust=1731937650481886&amp;usg=AOvVaw2UZZ7cGnDh_5e_mQOuA6I6"
          >
            [4]
          </a>
        </span>
        <span class="c3 c0">
          ), что в силу ст. ст. 435 и 438 Гражданского кодекса РФ является
          принятием (акцептом) оферты Банка. Фактическое начало использования
          Пользователем Приложения Банка является подтверждением присоединения
          Пользователя к условиям Соглашения по смыслу и в порядке п.5. ст.1286
          ГК РФ. Каждым использованием Приложения Банка Пользователь выражает
          согласие с условиями Соглашения в редакции, которая действовала на
          момент фактического использования Приложения Банка.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          6.2.Действие Соглашения распространяется на все последующие
          обновления/новые версии Приложения Банка. Соглашаясь с установкой
          обновления/новой версией Приложения Банка, Пользователь принимает
          условия Соглашения для соответствующих обновлений/новых версий
          Приложения Банка, если обновление/установка новой версии Приложения
          Банка не сопровождается иным лицензионным Соглашением.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          6.3.Соглашение может изменяться Банком в одностороннем порядке.
          Уведомление Пользователя о внесенных изменениях в условия Соглашения
          размещается в новой версии Приложения Банка и/или на Официальном сайте
          Банка. Указанные изменения вступают в силу с даты их публикации, если
          иное не оговорено в соответствующей публикации. Банк имеет право в
          любой момент отозвать данную Лицензию у любого Пользователя.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          6.4.Настоящим Банк заявляет, что он является единственным обладателем
          всех исключительных прав на Приложение Банка и правомочен
          распоряжаться ими по своему усмотрению, в рамках действующего
          законодательства.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          6.5.Настоящим Банк заявляет, что законодательство Российской Федерации
          подлежит применению к правам и обязанностям Пользователя и Банка по
          Соглашению, вне зависимости от места нахождения Банка, Пользователя
          или Устройства Пользователя.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          6.6. Все споры, разногласия и претензии, которые могут возникнуть в
          связи с исполнением, расторжением или признанием недействительным
          Соглашения, Пользователь и Банк будут стремиться решить путем
          переговоров с соблюдением обязательного претензионного порядка.
          Однако, если возникшие споры не представляется возможным решить путем
          переговоров, они будут разрешаться в судебном порядке в соответствии с
          законодательством Российской Федерации по месту нахождения Банка, язык
          судопроизводства - русский.
        </span>
      </p>
      <p class="c4 c10">
        <span class="c3 c0">
          6.7.В случае прекращения действия Соглашения или истечения срока
          Лицензии, Пользователь обязан незамедлительно прекратить любое
          использование Приложения Банка и удалить его из памяти всех Устройств.
        </span>
      </p>
      <p class="c4 c28">
        <span class="c3 c0">&nbsp;</span>
      </p>
      <p class="c2">
        <span class="c3 c18"></span>
      </p>
      <hr />
      <p class="c2">
        <span class="c3 c18"></span>
      </p>
      <p class="c29">
        <span class="c16">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftnref1&amp;sa=D&amp;source=editors&amp;ust=1731937650482721&amp;usg=AOvVaw1FmX_kaF7Smd-g3-WYDKWa"
          >
            [1]
          </a>
        </span>
        <span class="c23 c19">
          Наличие документа определяется Банком в зависимости от операционной
          системы Устройства Пользователя.
        </span>
      </p>
      <p class="c8">
        <span class="c16">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftnref2&amp;sa=D&amp;source=editors&amp;ust=1731937650482881&amp;usg=AOvVaw0eAc_eUBZXGAEzHbeZoynN"
          >
            [2]
          </a>
        </span>
        <span class="c19">&nbsp;</span>
        <span class="c23 c19">
          В зависимости от специфики работы операционной системы Устройства.
        </span>
      </p>
      <p class="c8">
        <span class="c16">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftnref3&amp;sa=D&amp;source=editors&amp;ust=1731937650483045&amp;usg=AOvVaw3Kq_VEakgUFZWfcXvOlHNS"
          >
            [3]
          </a>
        </span>
        <span class="c19">&nbsp;</span>
        <span class="c19 c23">
          При наличии у Пользователя договорных отношений с ООО «Сбербанк –
          Телеком».
        </span>
      </p>
      <p class="c8">
        <span class="c16">
          <a
            class="c5"
            href="https://www.google.com/url?q=https://www.sberbank.ru/common/img/uploaded/files/pdf/policy.html%23_ftnref4&amp;sa=D&amp;source=editors&amp;ust=1731937650483220&amp;usg=AOvVaw0-gTO_JzpXg2TWV14KFSfx"
          >
            [4]
          </a>
        </span>
        <span class="c19">&nbsp;</span>
        <span class="c23 c19">
          Кнопка/пункт меню Приложения Банка реализуется средствами программного
          обеспечения. Банк вправе изменять дизайн и наименование данной кнопки.
        </span>
      </p>
      <p class="c2">
        <span class="c3 c18"></span>
      </p>
    </Fragment>
  );
};

const PolicyPage = () => {
  return (
    <>
      <section className="partners">
        <Container>
          <Row>
            <Col className="col-12">
              <PolicyText />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PolicyPage;
