export function _debug(message, obj, type) {
  //console.log("!!!", window && window._indebug);
  if (window && window._indebug) {
    switch (type) {
      case "err": // error
        console.log("%c " + message, "background: red; color: #fff", obj);
        break;
      case "trace": // trace error
        console.trace("%c " + message, "background: red; color: #fff", obj);
        break;
      case "resp": // http response
        console.info("%c " + message, "background: #222; color: #bada55", obj);
        break;
      case "warn": // warning
        console.warn(
          "%c " + message,
          "background: #4f560f; color: #e6ff07",
          obj
        );
        break;
      case "gtm": // gtm events, special
        console.info(
          "%cGTM: " + message,
          "background: #03dbfc; color: #000",
          obj
        );
        break;
      default:
        if (obj) {
          console.info(
            "%c " + message,
            "background: #d9d9d9; color: #a82868; font-weight: bold;",
            obj
          );
        } else {
          console.info(
            "%c " + message,
            "background: #d9d9d9; color: #a82868; font-weight: bold;"
          );
        }
    }
  }
}

// set _indebug to true in your environment file, could be anywhere
window._indebug =
  !process.env.REACT_APP_PRODUCTION && process.env.REACT_APP_DEBUG;
