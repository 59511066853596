import { Container, Row, Col } from 'react-bootstrap';

const Advices = (props) => {
  const { data } = props;
  return (
    <section className="advices">
      <Container>
        {data.map((item, i) => {
          return (
            <div className="advices__item" key={i}>
              <Row
                className={
                  i % 2 === 1
                    ? 'd-flex justify-content-between flex-row-reverse'
                    : 'd-flex justify-content-between'
                }>
                <Col className="col-md-5 col-12">
                  <div className="advices__cover">
                    <img src={item.image} alt="phone" className="advices__img" />
                  </div>
                </Col>
                <Col className="col-md-5 col-12 align-self-center">
                  <div className="advices__txt">
                    <h2 className="advices__title">{item.title}</h2>
                    <p className="advices__description">{item.description}</p>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </Container>
    </section>
  );
};

export default Advices;
