import { Col, Container, Row } from "react-bootstrap";
import { dataNumbers } from "../../../data/dataNumbers";

const Numbers = () => {
  const { items } = dataNumbers;

  const NumbersItem = (props) => {
    const { data } = props;
    return (
      <div className="numbers__item">
        <img src={data.image} alt="" className="numbers__img" />
        <div className="numbers__wrapper">
          <div className="numbers__item-title">{data.title}</div>
          <div className="numbers__item-descr">{data.description}</div>
        </div>
      </div>
    );
  };

  const Certificate = (props) => {
    const { data } = props;
    return (
      <div className="certificate">
        <Container>
          <Row>
            <Col md={{ offset: 2, span: 8, offset: 2 }}>
              <div className="certificate__wrapper">
                <img src={data.image} alt="" className="certificate__img" />
                <h2 className="certificate__title">{data.title}</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <a href="#" className="certificate__link">
                MORE DETAILS
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  return (
    <>
      <div className="numbers">
        <Container>
          <Row>
            {items &&
              items.map((item, i) => {
                return (
                  <Col className="col-md-4 col-sm-12 pt-3 col-12 pt-3" key={i}>
                    <NumbersItem data={item} />
                  </Col>
                );
              })}
          </Row>
          {/* <Row>
            <Col>
              <Certificate data={dataNumbers} />
            </Col>
          </Row>*/}
        </Container>
      </div>
    </>
  );
};

export default Numbers;
