import Logo from "../components/UI/Logo";
import { AppStoreSvg, GooglePlaySvg } from "../components/UI/Svg";

export const dataDownload = {
  title: "Скачай приложение GuliGuli прямо сейчас!",
  description:
    "Сделай свой голос опорой и поддержкой и у тебя под рукой вся необходимая информации",
  link1: <AppStoreSvg />,
  link2: <GooglePlaySvg />,
  logo: <Logo />,
};
