import { FaInstagram, FaTelegramPlane, FaVk, FaWhatsapp } from "react-icons/fa";

const Social = ({ light, data }) => {
  const dataSocial = [
    {
      icon: null,
      link: "",
    },
  ];

  return (
    <div className={`social ${light ? "social-light" : ""}`}>
      <ul className="social__list">
        <li className="social__list-item">
          <a
            href="https://t.me/guliguli_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social__list-icon" />
          </a>
        </li>
        <li className="social__list-item">
          <a
            href="https://t.me/guliguli_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegramPlane className="social__list-icon" />
          </a>
        </li>
        <li className="social__list-item">
          <a
            href="https://vk.com/guliguli_app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaVk className="social__list-icon" />
          </a>
        </li>
        <li className="social__list-item">
          <a
            href="https://wa.me/79259958790"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="social__list-icon" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
