import { Col, Container, Row } from "react-bootstrap";

const Partners = (props) => {
  const { data } = props;
  return (
    <section className="partners">
      <Container>
        <Row>
          <Col className="col-md-3 me-5">
            <img src={data.imageLeft} alt="" className="partners__img" />
          </Col>
          <Col className="col-md-8 d-flex flex-column justify-content-center gap-5 col-12">
            <h2 className="partners__title title-dark">{data.title}</h2>
            <div className="partners__imgs">
              {/*{data.images.map((item, i) => {
                return <img src={item} alt="partners-logo" />;
              })}*/}

              {/* <img src={imgSm2} alt="" />
              <img src={imgSm3} alt="" /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
